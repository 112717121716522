
export enum SERVICE {
  THOMAS,
  STATIC_ASSETS_CDN
}

export const getRegionalUrl = (service: SERVICE) => {
  const basedUrl = "https://" + window.location.hostname;

  switch (service) {
    case SERVICE.STATIC_ASSETS_CDN:
      return basedUrl + "/static-assets";
    default:
      return basedUrl;
  }
};
